import React from 'react';



export const NoMatch = () =>(
    <div >
        <p>beaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaans on the about page now m8</p>
        
    </div>
        
        
        
        
)