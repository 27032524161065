import React, { Component, useState } from 'react';
import Navbar from 'react-bootstrap/NavbarBrand';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import './App.css';
import {Home} from './Home';
import {About} from './About';
import {Contact} from './Contact';
import {NoMatch} from './NoMatch';
import { render } from 'react-dom';
import {Layout} from './Components/Layouts';
import { Parallax } from 'react-scroll-parallax';
import { ParallaxProvider } from 'react-scroll-parallax';

import * as ReactBootStrap from 'react-bootstrap';

import NavDropdown from 'react-bootstrap/NavDropdown';

// import {NavigationBar} from ' ./Home';
//import NavigationBar from 'react-bootstrap/Navbar';
import NavigationBar from './Components/NavigationBar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import Image from 'react-bootstrap/Image'







//rfce


function App() {
  return (
    <>
    <Router>

    
    <NavigationBar/>

    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/about" component={About} />
      <Route path="/contact" component={Contact} />
      <Route component={NoMatch} /> 
    </Switch>
    


    </Router>
    </>
  )
}

export default App





    
    
  //   // <div className = "App"> 
  //   //   {/* <h2> Brandon Smith</h2>
  //   //   <NavigationBar />
  //   //   <Layout id = "mainContainerLayout" style = {{display: 'flex'}}>
  //   // <Router>
  //   //   <Switch>
  //   //     <Route exact path="/" component={Home} />
  //   //     <Route path="/about" component={About} />
  //   //     <Route path="/contact" component={Contact} />
  //   //     <Route component={NoMatch} /> 
  //   //   </Switch>
  //   // </Router>
    
    
  //   // </Layout> */}

    
  
  //  </div>