import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
//import Button from 'react-bootstrap/Button';
import './Home.css';
import Modal from 'react-bootstrap/Modal';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Nav';
import FormControl from 'react-bootstrap/FormControl';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import { Parallax } from 'react-scroll-parallax';
import { ParallaxProvider } from 'react-scroll-parallax';
import {Layout} from './Components/Layouts';

import {Button} from './Components/Button';

//import Grid from 'react-bootstrap/Grid';
//import {Grid} from 'react-bootstrap';
//import {render} from 'react-dom';


export const Home = () =>(
    //<div id = "mainheader">
        //
        
        //<div id = "introText">
        
        
        <React.Fragment>
        
        

        <Card id="greetingcardMain" className="shadow-lg p-3 rounded mb-5 " style={{ width: 'auto' }}>
        {/* //image would go here */}
        <Card.Body id = "greetingCard" >
        <Card.Title id = "greetingTitle"><b>Welcome</b></Card.Title>
        <Card.Text>
            Welcome to my portfolio!
            Here is where I keep an up to date collection of my projects so far. Whether they are complete or still in the development process, this is where to find them.
            GitHub links are provided for source code and demo executables are also provided as download links on the 'more info' for each project respectively.
            
            Contact information can be found here, and any questions can be submitted here.
            <p>- Brandon Smith</p>         
            
        </Card.Text>

        <Button variant="primary">Go somewhere</Button>
        </Card.Body>
        </Card>


        
        {/* <Container id = "pictureContainer"  fluid>
        <Row>

          <Col id = "uniPic" sm = {4}> <Image id = "uPic"  src={require('./Images/roundway-03.png')} rounded />  </Col> 

          <Col id = "uniPic" sm = {4}> <Image id = "uPic"  src={require('./Images/roundway-03.png')} rounded />  </Col>

          <Col id = "roundwayPic" sm = {4}> <Image id= "rPic"src={require('./Images/roundway-02.png')} rounded /> </Col>
      
        </Row>

        <Row>
        <Col id = "uniPic"  sm = {4}>  <Image id = "uPic"  src={require('./Images/roundway-03.png')} rounded />  </Col> 
        <Col id = "roundwayPic" sm = {4}>  <Image id= "rPic"src={require('./Images/roundway-02.png')} rounded /> </Col>
        <Col id = "uniPic"  sm = {4}> <Image id = "uPic"  src={require('./Images/roundway-03.png')} rounded />  </Col>
          </Row>

          <Row>
          <Col id = "uniPic" sm = {4}>  <Image id = "uPic"  src={require('./Images/roundway-03.png')} rounded />  </Col>
          <Col id = "uniPic"sm = {4}>  <Image id = "uPic"  src={require('./Images/roundway-03.png')} rounded />  </Col> 
        <Col id = "roundwayPic"sm = {4}>  <Image id= "rPic"src={require('./Images/roundway-02.png')} rounded /> </Col>
          </Row>

          <Row>
          <Col id = "uniPic" sm = {4}>  <Image id = "uPic"  src={require('./Images/roundway-03.png')} rounded />  </Col> 
          <Col id = "uniPic" sm = {4}>  <Image id = "uPic"  src={require('./Images/roundway-03.png')} rounded />  </Col>
        <Col id = "roundwayPic"sm = {4}>  <Image id= "rPic"src={require('./Images/roundway-02.png')} rounded /> </Col>
          </Row>

      
      
         
      </Container> */}
      
      <ParallaxProvider >
    <Parallax id = "parralax" className="custom-class" y={[-20, 20]}  tagOuter="figure" style="transform: translate3d(-10%, 8.53993%, 0px">
    {/* images go here */}
    </Parallax>
    </ParallaxProvider>
        </React.Fragment>
        
        


        



    
    
    
    
    
)
 
 


  


