
//import React from "react";
import React, { Component, useState , useEffect } from 'react';

//import Navbar from 'react-bootstrap/NavbarBrand';
import { BrowserRouter as Router, Link, Route, Switch} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Layout} from './Layouts';
// import Button from 'react-bootstrap/Button';

import {Button} from './Button';


import * as ReactBootStrap from 'react-bootstrap';

import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Nav';
import FormControl from 'react-bootstrap/FormControl';
import NavLink from 'react-bootstrap/NavLink';
import styled, { withTheme } from 'styled-components';


import './NavBar.css' ;


 

function NavigationBar() {



  const [show, setShow] = useState(true);

  const controlNavbar = () =>{
    if(window.scrollY>10){
      setShow(false);

    }else{
      setShow(true)
    }
    
  }
  useEffect(() => {
    window.addEventListener('scroll', controlNavbar)
    return () => {
      window.removeEventListener('scroll',controlNavbar)
    }
  }, [])

  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => setClick(false);

  const [button,setButton] = useState(true);

  const showButton = () =>
  {
    if(window.innerWidth <= 960)
    {
      setButton(false);
    }
    else{
      setButton(true);
    }
  }

  window.addEventListener('resize',showButton);

  return (
  <>

    <nav className = {`navbar ${show && 'navbar__scroll'}`} >
      <div className = 'navbar-container'>
        <Link to = '/' className = 'navbar-logo'> 
          Portfolio by Brandon Smith
        </Link>

        <div className = 'menu-icon' onClick = {handleClick}>
          <i className = {click ? 'fas fa-times' : 'fas fa-bars'}/>
        </div>
        <ul className = {click ? 'nav-menu active' : 'nav-menu'}>

          <li className = 'nav-item'>
            <Link to='/' className = 'nav-links' onClick={closeMobileMenu}>
              Home
              </Link>
            </li>
            
          <li className = 'nav-item'>
            <Link to='/projects' className = 'nav-links' onClick={closeMobileMenu}>
              Projects
              </Link>
            </li>
            
          <li className = 'nav-item'>
            <Link to='/about' className = 'nav-links' onClick={closeMobileMenu}>
              About
              </Link>
            </li>
          </ul>

          {/* {button && <Button buttonStyle='btn--outline'> test  </Button> } */}
      </div>

    </nav>
  </>
  );
}

export default NavigationBar






























// export default class NavigationBar extends Component {
//   constructor(props) {
//     super(props);

//     this.listener = null;
//     this.state = {
//       status: "top"
     

//     };
//   }


//   componentDidMount() {
//     this.listener = document.addEventListener("scroll", (e) => {
//       var scrolled = document.scrollingElement.scrollTop;
//       if (scrolled >= 10) {
//         if (this.state.status !== "amir") {
//           this.setState({ status: "amir" });
//         }
//       } else {
//         if (this.state.status !== "top") {
//           this.setState({ status: "top" });
//         }
//       }
//     });
//   }

//   componentDidUpdate() {
//     document.removeEventListener("scroll", this.listener);
//   }

//   render() {

    

//     return (
    
//         <ReactBootStrap.Navbar id='navbar' expand="lg"   id = "navbar" class = "navbar navbar-fixed-top navbar-default" style={{
//                      background: this.state.status === "top" ? "transparent" : "#212121",
//                      color: this.state.status === "top" ? "white" : "blue",
//                      position: "fixed",
//                      display: 'flex',
//                      marginleft: '10%',
//                      marginright: '10%'
                     
//                    }} >
//         <ReactBootStrap.Navbar.Brand href="#home">Portfolio by Brandon Smith</ReactBootStrap.Navbar.Brand>
//         <ReactBootStrap.Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <ReactBootStrap.Navbar.Collapse id="basic-navbar-nav" >
//         <ReactBootStrap.Nav  style = {{display:'flex'}}>
//             <ReactBootStrap.Nav.Link href="/">Home</ReactBootStrap.Nav.Link>
//             <ReactBootStrap.Nav.Link href="/about">About</ReactBootStrap.Nav.Link>
//             <ReactBootStrap.Nav.Link href="/contact">Contact</ReactBootStrap.Nav.Link>
//         <ReactBootStrap.NavDropdown title="Dropdown" id="basic-nav-dropdown">
//         <ReactBootStrap.NavDropdown.Item href="">Action</ReactBootStrap.NavDropdown.Item>
//         <ReactBootStrap.NavDropdown.Item href="#action/3.2">Another action</ReactBootStrap.NavDropdown.Item>
//         <ReactBootStrap.NavDropdown.Item href="#action/3.3">Something</ReactBootStrap.NavDropdown.Item>
//         <ReactBootStrap.NavDropdown.Divider />
//         <ReactBootStrap.NavDropdown.Item href="#action/3.4">Separated link</ReactBootStrap.NavDropdown.Item>
//         </ReactBootStrap.NavDropdown>
        
//      </ReactBootStrap.Nav>
//     {/* <Form inline >
//       <FormControl type="text" placeholder="Search" className="mr-sm-2" />
//       <Button variant="outline-success">Search</Button>
//     </Form> */}
//    </ReactBootStrap.Navbar.Collapse>
//     </ReactBootStrap.Navbar>
 






  
//    );
    
//   }
// }








// import React, { Component, useState , useEffect } from 'react';

// import Navbar from 'react-bootstrap/NavbarBrand';
// import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Button from 'react-bootstrap/Button';
// // import './App.css';
// //  import {Home} from './Home';
// //  import {About} from './About';
// //  import {Contact} from './Contact';
// // import {NoMatch} from './NoMatch';
// // import { render } from 'react-dom';

// import * as ReactBootStrap from 'react-bootstrap';

// import NavDropdown from 'react-bootstrap/NavDropdown';
// import Nav from 'react-bootstrap/Nav';
// import Form from 'react-bootstrap/Nav';
// import FormControl from 'react-bootstrap/FormControl';
// import NavLink from 'react-bootstrap/NavLink';
// import styled, { withTheme } from 'styled-components';



// // .navbar{
// //     background-color: #222;
// // }
// // .navbar-brand, .navbar-nav .nav-link{
// //      color: white;
// // &:hover {
// //    color:white;
// //}
// // }



// const NavigationBar = () => {
//     //  const [isUserLogged, setIsUserLogged] = useState(false);
//      // const [getUsername, setUsername] = useState("");
//      // const shoppingCart = <FontAwesomeIcon icon={faShoppingCart} />;
//       //const admin = "admin";
//       const [navbar, setNavbar] = useState(false);

//       // const state = {navBackground:"red"};


//       // const testmethod = () => {
//       //   document.addEventListener("scroll", () => {
//       //     const backgroundcolor = window.scrollY < 10 ? "rgb(30,50,60)" : "blue";
    
//       //     this.setState({ navBackground: backgroundcolor });
//       //   });
//       // }


// // const state = {bg: "transparent"};
//       // window.addEventListener('scroll', testmethod);
// // class NavigationBar extends Component{

       

        

//       // const changeBackground = () =>{
           
//       //    if (window.scrollY >= 5)
//       //    {
//       //      console.log(window.scrollY)
//       //      setNavbar({ bg: "red" });
//       //    }
//       //      else{
//       //       setNavbar ({ bg: "transparent"});
//       //      }
        
//       //  }
//       //  window.addEventListener('scroll', changeBackground);
//       // componentDidMount(){

//       // document.addEventListener("scroll", () => {
//       //  const backgroundcolor = window.scrollY < 10 ? "rgb(30,50,60)" : "blue";
       
      
//       // });
//       // }
//       window.scroll(function() {
//          ('navbar').toggleClass('scrolled', (this).scrollTop() > 5);
//         console.log("working");
//        })
    
//       // const [navBackground, setNavBackground] = useState(false)
//       // const navRef = React.useRef()
//       // navRef.current = navBackground
//       // useEffect(() => {
//       //   const handleScroll = () => {
//       //     const show = window.scrollY > 10
//       //     if (navRef.current !== show) {
//       //       setNavBackground(show)
//       //     }
//       //   }
//       //   document.addEventListener('scroll', handleScroll)
//       //   return () => {
//       //     document.removeEventListener('scroll', handleScroll)
//       //   }
//       // }, [])


// //bg = {state.bg}
      
// // style={{
// //           background: `${state.navBackground}`,
// //           position: "fixed",
// //           top: "0px",
// //           width: "100%"
// //         }}


//       return (
        
//         <ReactBootStrap.Navbar className = {navbar ? 'navbar active' : 'navbar'} expand="lg"   id = "navbar" class = "navbar navbar-fixed-top navbar-default navbar-default.scrolled"  >
//         <ReactBootStrap.Navbar.Brand href="#home">Portfolio by Brandon Smith</ReactBootStrap.Navbar.Brand>
//         <ReactBootStrap.Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <ReactBootStrap.Navbar.Collapse id="basic-navbar-nav" className="justify-content-center" >
//         <ReactBootStrap.Nav  >
//             <ReactBootStrap.Nav.Link href="/">Home</ReactBootStrap.Nav.Link>
//             <ReactBootStrap.Nav.Link href="/about">About</ReactBootStrap.Nav.Link>
//             <ReactBootStrap.Nav.Link href="/contact">Contact</ReactBootStrap.Nav.Link>
//         <ReactBootStrap.NavDropdown title="Dropdown" id="basic-nav-dropdown">
//         <ReactBootStrap.NavDropdown.Item href="">Action</ReactBootStrap.NavDropdown.Item>
//         <ReactBootStrap.NavDropdown.Item href="#action/3.2">Another action</ReactBootStrap.NavDropdown.Item>
//         <ReactBootStrap.NavDropdown.Item href="#action/3.3">Something</ReactBootStrap.NavDropdown.Item>
//         <ReactBootStrap.NavDropdown.Divider />
//         <ReactBootStrap.NavDropdown.Item href="#action/3.4">Separated link</ReactBootStrap.NavDropdown.Item>
//         </ReactBootStrap.NavDropdown>
        
//     </ReactBootStrap.Nav>
//     {/* <Form inline >
//       <FormControl type="text" placeholder="Search" className="mr-sm-2" />
//       <Button variant="outline-success">Search</Button>
//     </Form> */}
//   </ReactBootStrap.Navbar.Collapse>
// </ReactBootStrap.Navbar>

            
//       );
//     };
    
//     export default NavigationBar;